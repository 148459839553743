const navigation = {
    onMenuToggle(event) {
        const navigationContainer = $(event.target).closest('.navigation-menu-container')[0];

        if (navigationContainer.getAttribute('aria-expanded') === 'false') {
            this.addFocus(navigationContainer);
        } else {
            this.resetNavigation();
        }
    },
    currentTarget: null,
    onClickCancel: false, // on hover is triggering in mobile, but we want onclick to fire first. this tracks a hover+click
    onTabUp(event) {
        if (event.keyCode !== 13) {
            return;
        }

        const navigationContainerClicked = $(event.target).closest('.navigation-menu-list-item--parent')[0];

        if (navigationContainerClicked.getAttribute('aria-expanded ' === 'false')) {
            this.resetNavigation();
            this.currentTarget = navigationContainerClicked;
            this.addFocus(navigationContainerClicked);
        } else {
            this.removeFocus(navigationContainerClicked);
        }
    },
    onMobileClick(event) {
        const navigationContainerClicked = $(event.target).closest('.navigation-menu-list-item--parent')[0];

        // Small screen but still using mouse
        if ((event.pointerType === 'mouse' || event.type === 'click') && (window.screen.width < 992 || window.innerWidth < 992)) {
            this.addFocus(navigationContainerClicked);
            return;
        }

        if (event.pointerType !== 'touch') {
            return;
        }

        // On Hover activated and On Click but we want to skip for hover + click
        if (this.currentTarget === navigationContainerClicked && this.onClickCancel === false) {
            this.resetNavigation();
            return;
        }

        // Just On Click
        if (this.currentTarget !== navigationContainerClicked) {
            this.addFocus(navigationContainerClicked);
        }
    },
    onMouseOut(event) {
        // Abort when mobile nav is showing.
        if (window.screen.width < 992 || window.innerWidth < 992) {
            return;
        }

        if (event.toElement !== this.currentTarget) {
            this.resetNavigation();
        }
    },
    onMouseOver(event) {
        const navigationContainerClicked = $(event.target).closest('.navigation-menu-list-item--parent')[0];

        // Abort when mobile nav is showing.
        if (window.screen.width < 992 || window.innerWidth < 992) {
            return;
        }

        if (this.currentTarget === navigationContainerClicked) {
            return;
        }

        this.resetNavigation();
        this.onClickCancel = true;
        this.currentTarget = navigationContainerClicked;
        this.addFocus(navigationContainerClicked);

        setTimeout(() => {
            this.onClickCancel = false;
        }, 0);
    },
    // Utility Methods
    resetNavigation() {
        // Complete Reset
        $('.focus').attr('aria-expanded', 'false').removeClass('focus');
        this.currentTarget = null;
    },
    addFocus: (elem) => {
        elem.setAttribute('aria-expanded', 'true');
        elem.classList.toggle('focus');
    },
    removeFocus: (elem) => {
        elem.setAttribute('aria-expanded', 'false');
        elem.classList.remove('focus');
    },
};

window.navigation = navigation;
window.addEventListener('resize', () => {
    navigation.resetNavigation;
});

document.addEventListener('DOMContentLoaded', () => {
    if (typeof navigation !== 'undefined') {
        const navItems = document.querySelectorAll("li[data-navitem='true']");
        const mobileMenuToggle = document.querySelector('.navigation-menu-toggle');

        navItems.forEach((navItem) => {
            navItem.addEventListener('click', navigation.onMobileClick.bind(navigation));
            navItem.addEventListener('mouseover', navigation.onMouseOver.bind(navigation));
            navItem.addEventListener('mouseout', navigation.onMouseOut.bind(navigation));
            navItem.addEventListener('keyup', navigation.onTabUp.bind(navigation));
        });
        if (mobileMenuToggle) {
            mobileMenuToggle.addEventListener('click', navigation.onMenuToggle.bind(navigation));
        }
    }
});
