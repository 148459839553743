console.log('                                                                      \n'
    + '                  *****                         *****                 \n'
    + '             ***************,             ,***************            \n'
    + '          *********************,       **********************         \n'
    + '        **************************   *************************        \n'
    + '       **************************** ****************************      \n'
    + '      ***********************************************************     \n'
    + '     ****        *************************************        ****    \n'
    + '    ***             *******************************             ***   \n'
    + '    *                ,***************************                 *   \n'
    + '                       *************************                      \n'
    + ' #%%%%                 *************************                 %%%%/\n'
    + ' %%%%%                  ***********************                  %%%%%\n'
    + '                        ***********************                       \n'
    + '                                                                      \n'
    + 'https://careers.fool.com/');
