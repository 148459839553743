let infotrackRetries = 5;
let gotInfotrack = false;

// Function to wait for Infotrack to load
function retry_infotrack() {
    infotrackRetries -= 1;
    if (!gotInfotrack) {
        if (infotrackRetries < 0) {
            console.error('Could not initialize infotrack');
        } else {
            try {
                Infotrack = Infotrack || window.Infotrack;
                gotInfotrack = true;
            } catch (e) {
                window.setTimeout(() => {
                    retry_infotrack();
                }, 500);
            }
        }
    }
}

retry_infotrack();

// Function to determine event label, basically last path of url
const get_event_label = function (url) {
    // Determine Event Label

    const last_char = url.slice(-1);
    if (last_char === '/') {
        const slugIndex = url.lastIndexOf('/', url.lastIndexOf('/') - 1);
        return url.substring(slugIndex + 1, url.length - 1);
    }
    // handle no trailing slash case
    const slugIndex = url.lastIndexOf('/');
    // If there are more characters after the last slash, it's querystring params and we want to capture what's before the slash
    if (slugIndex + 1 < url.length) {
        const split_path = url.split('/');
        return split_path[split_path.length - 2];
    }
    return url.substring(slugIndex + 1, url.length);
};

// Function to determine offer vertical for review links (Url path after /money/
const get_event_vertical = function (url) {
    const folderIndex = url.indexOf('/money/');
    const offer_vertical = url.substring(folderIndex, url.length).replace('/money/', '').replace(/\?.*/, '');
    const vertIndex = offer_vertical.indexOf('/');
    // even out offer_vertical to what BI is expecting
    if (offer_vertical.substring(0, vertIndex).indexOf('buying-stocks') > -1) {
        return 'brokerages';
    }
    return offer_vertical.substring(0, vertIndex);
};

// Function to send data to Infotrack
const send_tracking = function (element, eventAction, propertiesAndDimensions) {
    try {
        if (Infotrack) {
            Infotrack.ready(() => {
                Infotrack.trackLink(element, eventAction, propertiesAndDimensions);
            });
        } else {
            console.log('Infotrack was not ready.');
        }
    } catch (e) {
        console.log('Infotrack was not defined.');
    }
};

/*
 * Function to change out offer links for non-us consumers. Primarily consumed by Google Optimize
 * @param vertical_offer_slug  String - expected to contain the vertical/slug e.g. brokerages/etrade-hub
 * @param url_to_set String - The URL you'd like the offer link to go to instead.
 * @returns None
 */
export const replace_offer_links = function (vertical_offer_slug, url_to_set) {
    const offer_links = document.querySelectorAll(`a[href*="/offers/${vertical_offer_slug}/"]`);

    offer_links.forEach((element) => {
        element.setAttribute('href', url_to_set);
    });
};
window.replace_offer_links = replace_offer_links;

// Function to handle money/offers/ link tracking
const set_up_track_offer_click = function (element) {
    const href = element.getAttribute('href');

    // Determine Event Label
    const eventLabel = get_event_label(href);

    // Determine offer_vertical Custom Dimension
    const linkIndex = href.indexOf('/money/offers/');
    let offer_vertical = href.substring(linkIndex, href.length).replace('/money/offers/', '').replace(/\?.*/, '');

    offer_vertical = offer_vertical.replace(`/${eventLabel}/`, '');
    // even out offer_vertical to what BI is expecting
    if (offer_vertical.indexOf('bank-accounts') > -1) {
        offer_vertical = 'banking';
    }
    if (offer_vertical.indexOf('banks') > -1) {
        offer_vertical = 'banking';
    }
    if (offer_vertical.indexOf('cds') > -1) {
        offer_vertical = 'banking';
    }

    // Determine Event Category
    let eventCategory = 'offer_click';
    // Determine Event Action: default is text_link
    let eventAction = 'text_link';
    if (element.classList && element.classList.contains('btn')) {
        eventAction = 'offer_click_apply';
    }
    element.childNodes.forEach((child) => {
        if (child.nodeType === 1) {
            if (child.tagName.toLowerCase() === 'img') {
                eventAction = 'offer_click_image';
            } else if (child.classList && child.classList.contains('btn')) {
                eventAction = 'offer_click_apply';
            } else if (child.childNodes) {
                child.childNodes.forEach((kid) => {
                    if (kid.nodeType === 1) {
                        if (kid.tagName.toLowerCase() === 'img') {
                            eventAction = 'offer_click_image';
                        }
                    }
                });
            }
        }
    });
    if (element.offsetParent && element.offsetParent.classList) {
        if (element.offsetParent.classList.contains('promo-sticky-button')) {
            eventAction = 'offer_click_sticky';
        }
    }
    // This only runs on redirect pages
    if (element.dataset.page) {
        if (element.dataset.page === 'redirect') {
            eventCategory = 'offer_redirect_page';
            eventAction = 'impatient';
        }
    }

    const propertiesAndDimensions = {
        category: eventCategory,
        label: eventLabel,
        offer_vertical,
    };

    // See if offer_order is an attribute on element and add it to the properties
    if (element.dataset.offer_order) {
        propertiesAndDimensions.offer_order = element.dataset.offer_order;
    }
    send_tracking(element, eventAction, propertiesAndDimensions);
};

// Function to handle money/*review link tracking
const set_up_track_review_click = function (element) {
    // Every once in a while a product may have review in the name so let's not tag offers as reviews
    const href = element.getAttribute('href');
    // Determine Event Label
    let eventLabel = get_event_label(href).replace('-review', '');
    if (element.dataset.slug) {
        eventLabel = element.dataset.slug;
    }

    // Determine Action
    let eventAction = 'read_review_click';
    if (element.dataset.action) {
        eventAction = element.dataset.action;
    }

    // Set Event Category
    const eventCategory = 'ascent_review';

    const propertiesAndDimensions = {
        category: eventCategory,
        label: eventLabel,
        offer_vertical: get_event_vertical(href),
    };

    // See if offer_order is an attribute on element and add it to the properties
    if (element.dataset.offer_order) {
        propertiesAndDimensions.offer_order = element.dataset.offer_order;
    }
    send_tracking(element, eventAction, propertiesAndDimensions);
};

export function add_click_tracking() {
    // Force all attribution URLs to open in a new window & Add tracking event listener to offer links
    document.querySelectorAll('a[href *= "/money/offers/"]').forEach((element) => {
        element.setAttribute('target', '_blank');
        element.setAttribute('rel', 'sponsored noopener');
        set_up_track_offer_click(element);
    });

    // Add event listeners to review links
    document.querySelectorAll('a[href *= "review"][href *= "/money/"]:not([href*="/offers/"])').forEach((element) => {
        set_up_track_review_click(element);
    });
}

function add_convert_tracking() {
    if (typeof window === 'undefined' || typeof Infotrack === 'undefined') {
        // Fail silently if Infotrack or window is not available.
        return;
    }

    try {
        const experiments = window.convert?.data?.experiments || {};
        const currentExperiments = window.convert?.currentData?.experiments || {};

        for (const key in currentExperiments) {
            if (Object.prototype.hasOwnProperty.call(currentExperiments, key)) {
                const currentExperiment = currentExperiments[key];
                const experimentName = experiments[key]?.n || 'unknown experiment name';
                const experimentVariant = currentExperiment.variation_name || 'unknown variant';
                if (experimentName && experimentName !== 'unknown experiment name') {
                    Infotrack.track(experimentName, { category: 'Google Optimize', label: experimentVariant });
                }
            }
        }
    } catch (error) {
        console.error('Error in add_convert_tracking', error)
    }
}

if (document.readyState !== 'loading') {
    add_click_tracking();
    add_convert_tracking();
} else {
    document.addEventListener('DOMContentLoaded', add_click_tracking);
    document.addEventListener('DOMContentLoaded', add_convert_tracking);
}
// Attach the function to the window object
window.add_click_tracking = add_click_tracking;
